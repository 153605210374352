html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #fff;
	font-family: 'Neue Haas Grotesk', Helvetica, sans-serif;
	font-weight: 400;
	color: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    quotes: "“" "”";
}

@media (max-width: 500px) {
    html, body {
        height: auto;
    }
    body {
        overflow-x: hidden;
        overflow-y: scroll;
    }
    html {
        overflow-x: hidden;
        overflow-y: scroll;
        position: absolute;
        text-size-adjust: 100%;
    }
}

* {
	box-sizing: border-box;
    outline: none !important;
}

#app {
	height: 100%;
}

a {
    color: inherit;
}

/* FONTS */
@font-face {
    font-family: 'Neue Haas Grotesk';
    src: url('../fonts/HaasGrotDisp-55Roman.eot');
    src: url('../fonts/HaasGrotDisp-55Roman.woff2') format('woff2'),
        url('../fonts/HaasGrotDisp-55Roman.woff') format('woff'),
        url('../fonts/HaasGrotDisp-55Roman.ttf') format('truetype'),
        url('../fonts/HaasGrotDisp-55Roman.svg#HaasGrotDisp-55Roman') format('svg'),
        url('../fonts/HaasGrotDisp-55Roman.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk';
    src: url('../fonts/HaasGrotDisp-75Bold.eot');
    src: url('../fonts/HaasGrotDisp-75Bold.woff2') format('woff2'),
        url('../fonts/HaasGrotDisp-75Bold.woff') format('woff'),
        url('../fonts/HaasGrotDisp-75Bold.ttf') format('truetype'),
        url('../fonts/HaasGrotDisp-75Bold.svg#HaasGrotDisp-75Bold') format('svg'),
        url('../fonts/HaasGrotDisp-75Bold.eot?#iefix') format('embedded-opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Grotesk';
    src: url('../fonts/HaasGrotDisp-45Light.eot');
    src: url('../fonts/HaasGrotDisp-45Light.woff2') format('woff2'),
        url('../fonts/HaasGrotDisp-45Light.woff') format('woff'),
        url('../fonts/HaasGrotDisp-45Light.ttf') format('truetype'),
        url('../fonts/HaasGrotDisp-45Light.svg#HaasGrotDisp-45Light') format('svg'),
        url('../fonts/HaasGrotDisp-45Light.eot?#iefix') format('embedded-opentype');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'MicrogrammaStd-BoldExtended';
    src: url('../fonts/MicrogrammaStd-BoldExtended.eot?#iefix') format('embedded-opentype'),  url('../fonts/MicrogrammaStd-BoldExtended.woff') format('woff'), url('../fonts/MicrogrammaStd-BoldExtended.ttf')  format('truetype'), url('../fonts/MicrogrammaStd-BoldExtended.svg#MicrogrammaStd-BoldExtended') format('svg');
    font-weight: bold;
    font-style: normal;
}
